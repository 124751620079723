














































































import { Component, Vue } from 'vue-property-decorator'
import { signIn, getUser, getCredential } from '@/services/Identity'
import { User } from '@/models/User'
import Button from '@/components/Button.vue'
import Alert from '@/components/Alert.vue'

@Component({
  components: {
    Button,
    Alert
  }
})
export default class SignIn extends Vue {
  loading: boolean = false
  email: string = ''
  password: string = ''
  isPersistent: boolean = true
  error: string | null = null
  warning: string | null = null
  get isReauthentication(): boolean {
    return this.$route.query.reason && this.$route.query.reason === 'reauth'
  }

  created() {
    if (this.isReauthentication) {
      this.warning =
        'Bitte melde dich erneut an, sodass wir sicherstellen können dass dir dieses Konto gehört.'
    } else if (this.$route.query.source === 'forgot-password') {
      this.warning =
        'Wir haben dir eine E-Mail zur Rücksetzung deines Passworts zugesendet. Bitte überprüfe deinen Posteingang.'
    } else if (this.$route.query.target) {
      this.warning = 'Bitte melde dich an, um diese Seite zu sehen.'
    }
  }

  async signIn() {
    this.loading = true

    try {
      if (this.isReauthentication) {
        const user = getUser()
        const credential = await getCredential(this.email, this.password)
        await user.reauthenticateWithCredential(credential)
        const targetName: string = this.$route.query.target as string
        this.$router.push({ name: targetName })
      } else {
        await signIn(this.email, this.password, this.isPersistent)
      }
    } catch (error) {
      this.error = error.message
      this.loading = false
    }
  }
}
